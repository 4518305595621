import React from "react"

import { EditGuesser, InputGuesser } from "@api-platform/admin"

import {
  AutocompleteInput,
  DateInput,
  DeleteButton,
  Edit as ReactAdminEdit,
  ReferenceInput,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  useEditController,
} from "react-admin"

import { useParams } from "react-router-dom"

import { Toolbar } from "@mui/material"

import { getRole } from "../../utils/localStorage"

import { getFieldsByPermission } from "../../utils/permissions"

const EditEntity = (props) => {
  const { entity } = props
  const { fields, permissions } = entity
  const role = getRole()

  const { id } = useParams()
  const { record } = useEditController({ id })

  if (!permissions || !permissions[role] || !permissions[role].includes("u")) {
    return <p>Tuto entitu nelze editovat</p>
  }

  if (!fields) {
    return <EditGuesser {...props} />
  }

  const updateFields = getFieldsByPermission(fields, "u")

  const content = updateFields.map(function (field) {
    const { type, source, label, choices, hideEdit } = field

    if (record && hideEdit && hideEdit(record)) {
      return null
    }

    if (type === "date") {
      return <DateInput source={source} label={label} />
    }

    if (type === "reference") {
      const { reference, searchTextKey } = field
      return (
        <ReferenceInput
          key={field.key}
          source={source}
          label={label}
          reference={reference}
          filterToQuery={(searchText) => ({ [searchTextKey]: searchText })}
        >
          <AutocompleteInput optionText={searchTextKey} />
        </ReferenceInput>
      )
    }

    if (type === "SelectInput") {
      return <SelectInput source={source} label={label} choices={choices} />
    }

    if (type === "SelectArrayInput") {
      const { choices, defaultValue, disabled } = field
      return (
        <SelectArrayInput
          key={field.key}
          source={source}
          label={label}
          choices={choices}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      )
    }

    // TODO    ReferenceInput
    return <InputGuesser key={field.key} source={source} label={label} />
  })

  const EditToolbar = (props) => (
    <Toolbar {...props} sx={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton />
      {permissions[role].includes("d") && <DeleteButton mutationMode="pessimistic" />}
    </Toolbar>
  )

  return (
    <ReactAdminEdit actions={null} redirect="show" {...props}>
      <SimpleForm toolbar={<EditToolbar />}>{content}</SimpleForm>
    </ReactAdminEdit>
  )
}

export default EditEntity
