const set = (key, value) => {
  if (!value) {
    return localStorage.removeItem(key)
  }

  localStorage.setItem(key, value)
}

const get = (key) => {
  return localStorage.getItem(key)
}

const getRole = () => {
  return localStorage.getItem("permissions")
}

export { getRole, set, get }
