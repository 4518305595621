import { EditButton, FunctionField, Show, SimpleShowLayout, TopToolbar } from "react-admin"
import React from "react"

import { getRole } from "../../utils/localStorage"
import DateUtils from "../../utils/date"
import ContactFormEntity from "../contactForms"

const ShowActions = ({ permissions, role }) =>
  permissions[role].includes("u") ? (
    <TopToolbar>
      <EditButton />
    </TopToolbar>
  ) : null

const ShowEntity = ({ record }) => {
  const { permissions, label } = ContactFormEntity
  const role = getRole()

  return (
    <Show
      entity={ContactFormEntity}
      actions={<ShowActions permissions={permissions} role={role} />}
      title={label}
      sx={{
        width: "fit-content",
        margin: "2em",
        position: "relative",
      }}
    >
      <SimpleShowLayout
        sx={{
          width: "fit-content",
          padding: "2em 10em 5em 2em",
          position: "relative",
        }}
      >
        <FunctionField
          label="Datum a čas odeslání"
          render={() => DateUtils.formatDateToCzechFullFormat(new Date(record.createdAt))}
        />
        <FunctionField label="Jméno a příjmení" render={() => record.name} />
        <FunctionField label="E-mail" render={() => record.email} />
        <FunctionField
          label="Telefon"
          render={() => (record.phoneNumber ? record.phoneNumber : "-")}
        />
        <FunctionField
          label="Název společnosti"
          render={() => (record.companyName ? record.companyName : "-")}
        />
        <FunctionField
          label="Počet zaměstnanců"
          render={() => (record.numberOfEmployees ? record.numberOfEmployees : "-")}
        />
        <FunctionField label="Zpráva" render={() => record.message} />
        <img
          src="/icons/email.svg"
          alt="Admin Icon"
          style={{
            position: "absolute",
            width: "10em",
            height: "10em",
            right: "1em",
            top: "1em",
            zIndex: 1,
            opacity: 0.27,
          }}
        />
      </SimpleShowLayout>
    </Show>
  )
}

export default ShowEntity
