import axios from "axios"

import getBearer from "./getBearer"
import urlUtils from "../utils/url"

export default async (url, data) => {
  return await axios({
    method: "patch",
    url: urlUtils.getApiUrl(url),
    headers: {
      "Content-Type": "application/merge-patch+json",
      Authorization: getBearer(),
    },
    data: JSON.stringify(data),
  })
}
