import React from "react"

import Entity from "./Entity"
import entities from "../../entities"

function Entities() {
  const renderedEntities = []

  for (const key of Object.keys(entities)) {
    const entity = entities[key]
    renderedEntities.push(Entity({ entity }))
  }

  return renderedEntities
}

export default Entities
