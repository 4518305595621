import React, { useEffect } from "react"
import urlJoin from "proper-url-join"

import Loading from "./Layout/Loading"
import fetchProfile from "../utils/fetchProfile"
import * as localStorage from "../utils/localStorage"

function Login(props) {
  useEffect(async () => {
    const { token, refresh_token, reload_redirect } = props

    localStorage.set("token", token)
    localStorage.set("refresh_token", refresh_token)
    localStorage.set("tokenIssuedAt", new Date().toISOString())

    const profile = await fetchProfile()

    if (!profile) {
      // Fetch profile will redirect to web app
      return <Loading />
    }

    if (reload_redirect) {
      const url = urlJoin("/", reload_redirect)
      window.location.replace(url)
    } else {
      window.location.replace("/")
    }
  }, [])

  return <Loading />
}

export default Login
