import React from "react"

import ShowEntity from "./ShowEntity"
import DateUtils from "../../utils/date"

const CustomerIcon = () => <img src="/icons/customer.svg" alt="Customer Icon" />

export default {
  name: "api/customers",
  label: "Zákonní zástupci",
  permissions: {
    ROLE_ADMIN: "lr",
  },
  icon: CustomerIcon,
  list: {
    search: {
      source: "email",
      label: "Vyhledávání podle e-mailu",
    },
  },
  fields: {
    firstName: {
      type: "reference",
      label: "Jméno",
      source: "customerProfile",
      permissions: {
        ROLE_ADMIN: "lr",
      },
      render: (record) => {
        return `${record.firstName}`
      },
    },
    surname: {
      type: "reference",
      label: "Příjmení",
      source: "customerProfile",
      permissions: {
        ROLE_ADMIN: "lr",
      },
      render: (record) => {
        return `${record.surname}`
      },
    },
    email: {
      label: "E-mail",
      permissions: {
        ROLE_ADMIN: "lr",
      },
      render: (record) => {
        return record.email
      },
    },
    phone: {
      type: "reference",
      label: "Telefon",
      source: "customerProfile",
      permissions: {
        ROLE_ADMIN: "lr",
      },
      render: (record) => {
        return `${record.phoneNumberPrefix} ${record.phoneNumber}`
      },
    },
    createdAt: {
      label: "Datum registrace",
      permissions: {
        ROLE_ADMIN: "lr",
      },
      render: (record) => {
        return DateUtils.formatDateToCzechDMRFormat(new Date(record.createdAt))
      },
    },
  },
  show: ShowEntity,
}
