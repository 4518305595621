import { getRole } from "./localStorage"

const getFieldsByPermission = (fields, permission) => {
  const role = getRole()

  const filteredFields = []

  for (const fieldKey of Object.keys(fields)) {
    const field = fields[fieldKey]

    if (
      field.permissions &&
      field.permissions[role] &&
      field.permissions[role].includes(permission)
    ) {
      field.key = fieldKey
      filteredFields.push(field)
    }
  }

  return filteredFields
}

export { getFieldsByPermission }
