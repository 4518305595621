import React from "react"

import doctors from "./doctors"
import customers from "./customers"
import vouchers from "./vouchers"
import consultations from "./consultations"
import refundRequests from "./refundRequests"
import deletionRequests from "./deletionRequests"
import salesActions from "./salesActions"
import contactForms from "./contactForms"

/**
 *     permissions: {
 *       ROLE_ADMIN: "lcrud",
 *     }
 *
 *     "l" - LIST - the entity appears in main menu and renders in a LIST when it's active
 *     "c" - CREATE - new entity can be created (create button in the list and the CREATE page are rendered)
 *     "r" - READ - the entity detail can be displayed in SHOW page
 *     "u" - UPDATE - the entity can be updated (update button in both the list and SHOW page and also the UPDATE page are rendered)
 *     "d" - DELETE - the entity can be deleted (delete button in both the list and EDIT page are rendered)
 *
 *     the same applies for individual fields (except the "d" - that has no impact for individual fields)
 */
export default {
  CONSULTATIONS: consultations,
  VOUCHERS: vouchers,
  CUSTOMERS: customers,
  CONTACT_FORMS: contactForms,
  REFUND_REQUESTS: refundRequests,
  DELETION_REQUESTS: deletionRequests,
  SALES_ACTIONS: salesActions,
  DOCTORS: doctors,
  // ADMINS: admins, // TODO Split Doctors and Admins

  // TODO: https://mcorecode.atlassian.net/browse/DON-493
  // WORK_HOURS: workHours,

  // Only reference entitites:
  CONSULTATION_AUDIO_FILES: {
    name: "api/consultation-audio-files",
  },
  CUSTOMER_FILES: {
    name: "api/customer-files",
  },
  CUSTOMER_REVIEWS: {
    name: "api/customer-reviews",
  },
  DOCTOR_COMMENTS: {
    name: "api/doctor-consultation-comments",
  },
  CUSTOMER_PROFILES: {
    name: "api/customer-profiles",
  },
  CUSTOMER_CHILDREN: {
    name: "api/customer-childrens",
  },
}
