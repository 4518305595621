import React from "react"
import ShowEntity from "../users/ShowEntity"
import CreateEntity from "./CreateEntity"

const DoctorIcon = () => <img src="/icons/doctor.svg" alt="Doctor Icon" />

export default {
  name: "api/users",
  label: "Doktoři",
  customEntity: "DOCTOR",
  permissions: {
    ROLE_ADMIN: "lcr",
  },
  icon: DoctorIcon,
  list: {
    search: {
      source: "email",
      label: "Vyhledávání podle e-mailu",
    },
  },
  fields: {
    email: {
      source: "email",
      label: "E-mail",
      permissions: {
        ROLE_ADMIN: "lcr",
      },
      render: (record) => {
        return record.email
      },
    },
    firstName: {
      source: "firstName",
      label: "Jméno",
      permissions: {
        ROLE_ADMIN: "lcr",
      },
    },
    surname: {
      source: "surname",
      label: "Příjmení",
      permissions: {
        ROLE_ADMIN: "lcr",
      },
    },
    phoneNumberPrefix: {
      source: "phoneNumberPrefix",
      label: "Tel. předvolba",
      permissions: {
        ROLE_ADMIN: "cr",
      },
    },
    phoneNumber: {
      source: "phoneNumber",
      label: "Tel. číslo",
      permissions: {
        ROLE_ADMIN: "cr",
      },
    },
  },
  show: ShowEntity,
  create: CreateEntity,
}
