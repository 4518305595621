import { LinearProgress, ShowBase, SimpleShowLayout, useRecordContext } from "react-admin"
import React from "react"

import AdminShowEntity from "../admins/ShowEntity"
import DoctorShowEntity from "../doctors/ShowEntity"

const Show = () => {
  const record = useRecordContext()

  if (!record) {
    return (
      <SimpleShowLayout>
        <LinearProgress />
      </SimpleShowLayout>
    )
  }

  if (record.roles.includes("ROLE_ADMIN")) {
    return <AdminShowEntity record={record} />
  }

  return <DoctorShowEntity record={record} />
}

const ShowEntity = () => {
  // Needs to be wrapped like this as ShowBase provides useRecordContext and also hooks are not allowed here
  return (
    <ShowBase>
      <Show />
    </ShowBase>
  )
}

export default ShowEntity
