import urlUtils from "./url"

export default async (reason) => {
  localStorage.removeItem("token")
  localStorage.removeItem("refresh_token")
  localStorage.removeItem("permissions")

  const loggedOutUrl = urlUtils.getWebAppLoggedOutUrl(reason)
  window.location.replace(loggedOutUrl)
}
