import React from "react"

import { ROLE_DOCTOR } from "../../constants/roles"
import { role } from "../../constants/strings"

const AdminIcon = () => <img src="/icons/admin.svg" alt="Admin Icon" />

export default {
  name: "api/users",
  label: "Administrátoři",
  customEntity: "ADMIN",
  permissions: {
    ROLE_ADMIN: "lr",
  },
  icon: AdminIcon,
  list: {
    search: {
      source: "email",
      label: "Vyhledávání podle e-mailu",
    },
  },
  fields: {
    email: {
      source: "email",
      label: "E-mail",
      permissions: {
        ROLE_ADMIN: "lr",
      },
      render: (record) => {
        return record.email
      },
    },
    firstName: {
      source: "firstName",
      label: "Jméno",
      permissions: {
        ROLE_ADMIN: "lr",
      },
    },
    surname: {
      source: "surname",
      label: "Příjmení",
      permissions: {
        ROLE_ADMIN: "lr",
      },
    },
    phoneNumberPrefix: {
      source: "phoneNumberPrefix",
      label: "Tel. předvolba",
      permissions: {
        ROLE_ADMIN: "r",
      },
    },
    phoneNumber: {
      source: "phoneNumber",
      label: "Tel. číslo",
      permissions: {
        ROLE_ADMIN: "r",
      },
    },
    roles: {
      permissions: {
        ROLE_ADMIN: "r",
      },
      render: (record) => {
        if (!record.roles || !Array.isArray(record.roles) || record.roles.length === 0) {
          return "-"
        }

        return role(record.roles[0])
      },
      source: "roles",
      label: "Role",
      type: "SelectArrayInput",
      choices: [{ id: ROLE_DOCTOR, name: role(ROLE_DOCTOR) }],
      defaultValue: [ROLE_DOCTOR],
      disabled: true,
    },
  },
}
