const PEDIATRY = {
  iri: "/api/specializations/1",
  name: "pediatry",
  label: "Pediatrie",
}

const STOMATOLOGY = {
  iri: "/api/specializations/2",
  name: "pediatric-stomatology",
  label: "Stomatologie",
}

const GYNECOLOGY = {
  iri: "/api/specializations/3",
  name: "gynecology",
  label: "Gynekologie",
}

export { PEDIATRY, STOMATOLOGY, GYNECOLOGY }
