import { DISCOUNT, FREE_ACCESS, salesActionsType } from "../../constants/salesActionsTypes"
import CreateEntity from "./CreateEntity"
import React from "react"
import DateUtils from "../../utils/date"

const SalesActionIcon = () => <img src="/icons/sales.svg" alt="Sales Action Icon" />

export default {
  name: "api/sales-actions",
  label: "Akce",
  icon: SalesActionIcon,
  permissions: {
    ROLE_ADMIN: "lcru",
  },
  fields: {
    name: {
      source: "name",
      label: "Název",
      permissions: {
        ROLE_ADMIN: "lcru",
      },
    },
    salesActionType: {
      source: "salesActionType",
      label: "Typ",
      type: "SelectInput",
      choices: [
        { id: FREE_ACCESS, name: salesActionsType(FREE_ACCESS) },
        { id: DISCOUNT, name: salesActionsType(DISCOUNT) },
      ],
      render: (record) => {
        return salesActionsType(record.salesActionType)
      },
      permissions: {
        ROLE_ADMIN: "lcr",
      },
    },
    freeAccessesCount: {
      source: "freeAccessesCount",
      label: "Počet přístupů zdarma",
      render: (record) => {
        return record.salesActionType === FREE_ACCESS ? record.freeAccessesCount : "-"
      },
      permissions: {
        ROLE_ADMIN: "ru",
      },
      hideEdit: (record) => {
        return record.salesActionType !== FREE_ACCESS
      },
    },
    discountPercentage: {
      source: "discountPercentage",
      label: "Sleva",
      render: (record) => {
        return record.salesActionType === DISCOUNT ? `${record.discountPercentage}%` : "-"
      },
      permissions: {
        ROLE_ADMIN: "ru",
      },
      hideEdit: (record) => {
        return record.salesActionType !== DISCOUNT
      },
    },
    createdAt: {
      source: "createdAt",
      label: "Datum vytvoření",
      type: "date",
      permissions: {
        ROLE_ADMIN: "lr",
      },
      render: (record) => {
        return DateUtils.formatDateToCzechDMRFormat(new Date(record.createdAt))
      },
    },
    expirationDate: {
      source: "expirationDate",
      label: "Datum expirace",
      type: "date",
      permissions: {
        ROLE_ADMIN: "lcru",
      },
      render: (record) => {
        return DateUtils.formatDateToCzechDMRFormat(new Date(record.expirationDate))
      },
    },
  },
  create: CreateEntity,
}
