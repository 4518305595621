import * as React from "react"
import LabelIcon from "@mui/icons-material/Label"
import { Menu, useRefresh } from "react-admin"
import { useNavigate } from "react-router-dom"

import entities from "../../entities"
import { ROLE_ADMIN } from "../../constants/roles"
import logout from "../../utils/logout"
import { ACCESS_DENIED } from "../../constants/loggedOutReasons"
import { getRole } from "../../utils/localStorage"
import * as CUSTOM_ROUTES from "../../constants/customRoutes"

const AdminEntities = () => {
  const adminEntities = []

  for (const key of Object.keys(entities)) {
    const entity = entities[key]

    if (
      !entity.permissions ||
      !entity.permissions[ROLE_ADMIN] ||
      !entity.permissions[ROLE_ADMIN].includes("l")
    ) {
      continue
    }

    const icon = entity.icon ? <entity.icon /> : <LabelIcon />
    adminEntities.push(<Menu.Item to={entity.name} primaryText={entity.label} leftIcon={icon} />)
  }

  return adminEntities
}

const MenuItems = () => {
  const refresh = useRefresh()
  const navigate = useNavigate()

  const role = getRole()

  let Entities = <></>

  // TODO: Duplicity from ./Entities
  switch (role) {
    case ROLE_ADMIN:
      Entities = AdminEntities()
      break

    default:
      return logout(ACCESS_DENIED)
  }

  const customEntities = [
    {
      url: CUSTOM_ROUTES.ADMINS,
      icon: "/icons/admin.svg",
      label: "Administrátoři",
    },
  ]

  const CustomEntities = customEntities.map(function (entity) {
    const { url, icon, label } = entity

    return (
      <div
        onClick={() => {
          navigate(url)
          refresh()
        }}
        style={{
          display: "flex",
          alignItems: "center",
          padding: "6px 16px",
          cursor: "pointer",
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#f1f1f1")}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#fafafb")}
      >
        <img
          src={icon}
          alt="Icon"
          style={{
            width: "20px",
            height: "20px",
            marginRight: "20px",
          }}
        />
        <span style={{ color: "#000" }}>{label}</span>
      </div>
    )
  })

  return (
    <Menu>
      {Entities}
      {CustomEntities}
    </Menu>
  )
}

export default MenuItems
