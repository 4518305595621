import * as React from "react"
import { Box, Typography } from "@mui/material"

export default () => (
  <Box style={{ flex: 1, padding: "2rem" }} textAlign="center" m={1}>
    <Typography
      variant="h5"
      paragraph
      style={{
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        color: "rgba(0, 0, 0, 0.5)",
      }}
    >
      404: Stránka nenalezena
    </Typography>
  </Box>
)
