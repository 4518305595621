export default {
  formatDateToCzechDMRFormat: (date) => {
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0") // Month is zero-based
    const year = date.getFullYear()
    return `${day}. ${month}. ${year}`
  },
  formatDateToCzechFullFormat: (date) => {
    date = new Date(date.toLocaleString("en-US", { timeZone: "Europe/Vienna" }))
    const day = String(date.getDate()).padStart(2, "0")
    const month = String(date.getMonth() + 1).padStart(2, "0") // Month is zero-based
    const year = date.getFullYear()
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")

    return `${day}. ${month}. ${year} ${hours}:${minutes}`
  },
}
