import { styled } from "@mui/system"

export const CustomLabel = styled("p")({
  fontSize: "0.75rem",
  color: "rgba(0, 0, 0, 0.6)",
  fontWeight: 400,
  marginBottom: "0.2rem",
  lineHeight: 1.5,
  letterSpacing: "0.00938em",
})
