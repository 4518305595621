import CreateEntity from "./CreateEntity"
import { voucherState } from "../../constants/strings"
import React from "react"
import DateUtils from "../../utils/date"

const VoucherIcon = () => <img src="/icons/voucher.svg" alt="Voucher Icon" />

export default {
  name: "api/vouchers",
  label: "Vouchery",
  icon: VoucherIcon,
  permissions: {
    ROLE_ADMIN: "lcr",
  },
  fields: {
    code: {
      source: "code",
      label: "Kód",
      permissions: {
        ROLE_ADMIN: "lr",
      },
    },
    customer: {
      type: "reference",
      source: "customer",
      label: "E-mail zákonného zástupce",
      reference: "api/customers",
      textFieldSource: "email",
      searchTextKey: "email",
      permissions: {
        ROLE_ADMIN: "lr",
      },
    },
    creator: {
      type: "reference",
      source: "creator",
      label: "Tvůrce",
      reference: "api/users",
      textFieldSource: "email",
      searchTextKey: "email",
      permissions: {
        ROLE_ADMIN: "lr",
      },
    },
    createdAt: {
      source: "createdAt",
      label: "Datum a čas vytvoření",
      permissions: {
        ROLE_ADMIN: "lr",
      },
      render: (record) => {
        return DateUtils.formatDateToCzechFullFormat(new Date(record.createdAt))
      },
    },
    createExpiration: {
      source: "statusTimer",
      type: "date",
      label: "Datum a čas expirace",
      permissions: {
        ROLE_ADMIN: "c", // Status is created when creating voucher, so it's possible to set the expiration date
      },
    },
    usageCounterLimit: {
      source: "usageCounterLimit",
      label: "Počet použití",
      permissions: {
        ROLE_ADMIN: "r", // Status is created when creating voucher, so it's possible to set the expiration date
      },
    },
    detailStatus: {
      source: "status",
      label: "Stav",
      permissions: {
        ROLE_ADMIN: "r",
      },
      render: (record) => {
        if (!record.status) {
          return "-"
        }

        return voucherState(record.status)
      },
    },
    detailStatusTimer: {
      source: "statusTimer",
      label: "Datum a čas změny stavu",
      permissions: {
        ROLE_ADMIN: "r",
      },
      render: (record) => {
        return DateUtils.formatDateToCzechFullFormat(new Date(record.statusTimer))
      },
    },
  },
  list: {
    search: {
      source: "code",
      label: "Vyhledávání podle kódu",
    },
  },
  create: CreateEntity,
}
