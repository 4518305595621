const role = (role) => {
  switch (role.toUpperCase()) {
    case "ROLE_MANAGER":
      return "Manažer"
    case "ROLE_DOCTOR":
      return "Doktor"
    case "ROLE_ADMIN":
      return "Admin"
    default:
      return "-"
  }
}

const refundState = (refundState) => {
  switch (refundState.toUpperCase()) {
    case "CREATED":
      return "Vytvořeno"
    case "REFUNDED":
      return "Refundováno"
    case "DENIED":
      return "Zamítnuto"
    default:
      return "-"
  }
}

const voucherState = (voucherState) => {
  switch (voucherState.toUpperCase()) {
    case "CREATED":
      return "Vytvořen"
    case "SPENT":
      return "Použit"
    case "DEACTIVATED":
      return "Zneplatněn"
    default:
      return "-"
  }
}

export { role, refundState, voucherState }
