import "proxy-polyfill"

import * as React from "react"
import ReactDOM from "react-dom"

import App from "./App"
import RollbarProvider from "./services/Rollbar"

ReactDOM.render(
  <React.StrictMode>
    <RollbarProvider>
      <App />
    </RollbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
)
