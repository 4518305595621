import React from "react"
import MailIcon from "@mui/icons-material/Mail"

import DateUtils from "../../utils/date"
import ShowEntity from "./ShowEntity"

export default {
  name: "api/contact-forms",
  label: "Kontaktní formuláře",
  icon: MailIcon,
  permissions: {
    ROLE_ADMIN: "lr",
  },
  fields: {
    type: {
      source: "type",
      label: "Typ formuláře",
      render: (record) => {
        return record.type === "CONTACT_FORM_EMPLOYER" ? "Zaměstnavatelé" : "Doktoři"
      },
      permissions: {
        ROLE_ADMIN: "l",
      },
    },
    name: {
      source: "name",
      label: "Jméno a příjmení",
      permissions: {
        ROLE_ADMIN: "lr",
      },
    },
    email: {
      source: "email",
      label: "E-mail",
      permissions: {
        ROLE_ADMIN: "lr",
      },
    },
    companyName: {
      source: "companyName",
      label: "Název společnosti",
      render: (record) => {
        return record.type === "CONTACT_FORM_EMPLOYER"
          ? record.companyName
            ? record.companyName
            : "-"
          : "-"
      },
      permissions: {
        ROLE_ADMIN: "lr",
      },
    },
    createdAt: {
      source: "createdAt",
      label: "Datum a čas odeslání",
      permissions: {
        ROLE_ADMIN: "lr",
      },
      render: (record) => {
        return DateUtils.formatDateToCzechFullFormat(new Date(record.createdAt))
      },
    },
  },
  list: {
    search: {
      source: "email",
      label: "Vyhledávání podle E-mailu",
    },
  },
  show: ShowEntity,
}
