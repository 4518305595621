import React from "react"

import { CreateGuesser, InputGuesser } from "@api-platform/admin"

import { AutocompleteInput, ReferenceInput, SelectArrayInput, SelectInput } from "react-admin"

import { getRole } from "../../utils/localStorage"

import { getFieldsByPermission } from "../../utils/permissions"

const CreateEntity = (props) => {
  const { entity } = props
  const { fields, create } = props.entity
  const role = getRole()

  if (!entity.permissions || !entity.permissions[role] || !entity.permissions[role].includes("c")) {
    return <p>Tuto entitu nelze vytvářet</p>
  }

  if (create) {
    return create(props)
  }

  if (!fields) {
    return <CreateGuesser {...props} />
  }

  const createFields = getFieldsByPermission(fields, "c")

  const content = createFields.map(function (field) {
    const { type, source, label, choices } = field

    if (type === "reference") {
      const { reference, searchTextKey } = field
      return (
        <ReferenceInput
          key={field.key}
          source={source}
          label={label}
          reference={reference}
          filterToQuery={(searchText) => ({ [searchTextKey]: searchText })}
        >
          <AutocompleteInput optionText={searchTextKey} />
        </ReferenceInput>
      )
    }

    if (type === "SelectInput") {
      return <SelectInput source={source} choices={choices} />
    }

    if (type === "SelectArrayInput") {
      const { choices, defaultValue, disabled } = field
      return (
        <SelectArrayInput
          key={field.key}
          source={source}
          label={label}
          choices={choices}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      )
    }

    return <InputGuesser key={field.key} source={source} label={label} />
  })

  return (
    <CreateGuesser redirect="show" {...props}>
      {content}
    </CreateGuesser>
  )
}

export default CreateEntity
