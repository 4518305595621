import React from "react"
import Login from "./components/Login"
import Admin from "./components/Admin"
import urlUtils from "./utils/url"
import { GENERAL } from "./constants/loggedOutReasons"
import { getRole } from "./utils/localStorage"

function App() {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())

  const { token, refresh_token, reload_redirect } = params
  let refreshToken
  let showLogin = false

  if (token || refresh_token) {
    // Was redirected from the web app or the token is being refreshed
    showLogin = true
    refreshToken = refresh_token
  } else {
    refreshToken = localStorage.getItem("refresh_token")
    const tokenIssuedAt =
      localStorage.getItem("tokenIssuedAt") && new Date(localStorage.getItem("tokenIssuedAt"))
    // Refresh token if it's older than 30 minutes
    const outdated = tokenIssuedAt && tokenIssuedAt < new Date(new Date().getTime() - 30 * 60000)

    if (outdated && refreshToken) {
      // Admin was reopened and there is refresh_token stored in local storage
      showLogin = true
    }
  }

  if (showLogin) {
    return <Login token={token} refresh_token={refreshToken} reload_redirect={reload_redirect} />
  }

  // Is logged in
  if (getRole()) {
    return <Admin />
  }

  const loggedOutUrl = urlUtils.getWebAppLoggedOutUrl(GENERAL)
  window.location.replace(loggedOutUrl)

  return <></>
}

export default App
