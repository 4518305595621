import { parseHydraDocumentation } from "@api-platform/api-doc-parser"

import {
  fetchHydra as baseFetchHydra,
  hydraDataProvider as baseHydraDataProvider,
} from "@api-platform/admin"

import entities from "./entities"
import updateEntitiy from "./api/updateEntitiy"
import urlUtils from "./utils/url"
import React from "react"
import fetchProfile from "./utils/fetchProfile"

const ENTRYPOINT = urlUtils.getApiUrl()

const getHeaders = () =>
  localStorage.getItem("token")
    ? {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    : {}

const waitTillRefreshIsDone = () => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      if (!window.refreshingToken) {
        clearInterval(interval)
        resolve()
      }
    }, 250)
  })
}

const processRequest = async (request) => {
  if (window.refreshingToken) {
    await waitTillRefreshIsDone()
    return request()
  }

  const tokenIssuedAt =
    localStorage.getItem("tokenIssuedAt") && new Date(localStorage.getItem("tokenIssuedAt"))
  // Refresh token if it's older than 50 minutes
  const outdated = tokenIssuedAt && tokenIssuedAt < new Date(new Date().getTime() - 50 * 60000)

  if (!outdated) {
    return request()
  }

  window.refreshingToken = true
  const profile = await fetchProfile(true)
  window.refreshingToken = false

  if (profile) {
    return request()
  }
}

const fetchHydra = async (url, options = {}) => {
  return processRequest(() => {
    return baseFetchHydra(url, {
      ...options,
      headers: getHeaders,
    })
  })
}

const apiDocumentationParser = async () => {
  try {
    const headers = getHeaders()
    return await parseHydraDocumentation(ENTRYPOINT, { headers })
  } catch (result) {
    const { api, response, status } = result
    if (status !== 401 || !response) {
      throw result
    }
    // Prevent infinite loop if the token is expired
    localStorage.removeItem("permissions")
    localStorage.removeItem("token")
  }
}

const dataProvider = baseHydraDataProvider({
  entrypoint: ENTRYPOINT,
  httpClient: fetchHydra,
  apiDocumentationParser,
})

dataProvider.update = async (resource, params) => {
  const updateData = {}

  for (const entityKey of Object.keys(entities)) {
    const entity = entities[entityKey]

    if (entity.name === resource) {
      for (const fieldKey of Object.keys(entity.fields)) {
        updateData[fieldKey] = params.data[fieldKey]
      }
    }
  }

  try {
    const res = await updateEntitiy(params.id, updateData)

    // React admin uses ID and Hydra admin uses IRI
    // (Hydra data provider has convertReactAdminDataToHydraData and convertHydraDataToReactAdminData methods)
    res.data.id = res.data["@id"]

    return { data: res.data }
  } catch (err) {
    console.error(err)
    return Promise.reject("Update selhal")
  }
}

export default dataProvider
