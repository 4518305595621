import {
  Datagrid,
  EditButton,
  FunctionField,
  LinearProgress,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  useReference,
} from "react-admin"

import React from "react"
import { Typography } from "@mui/material"

import Audio from "../../components/Files/Audio"
import File from "../../components/Files/File"
import { CustomLabel } from "../../components/Components/CustomLabel"
import { Styles } from "../../styles/Styles"
import { getRole } from "../../utils/localStorage"
import DateUtils from "../../utils/date"

const Consultation = () => {
  const record = useRecordContext()
  const { centerStyle } = Styles()
  const { isLoading: isCustomerRefLoading, referenceRecord: customerRef } = useReference({
    id: record?.customer ?? 0,
    reference: "users",
  })
  const { isLoading: isCustomerProfileLoading, referenceRecord: customerProfileRef } = useReference(
    {
      id: customerRef?.customerProfile ?? 0,
      reference: "customer-profiles",
    }
  )
  if (!record) {
    return (
      <SimpleShowLayout>
        <LinearProgress />
      </SimpleShowLayout>
    )
  }

  const Files =
    record.customerFiles &&
    record.customerFiles.map(
      (file) => file.contentUrl && <File url={file.contentUrl} fileId={file["@id"]} />
    )

  return (
    <SimpleShowLayout>
      <FunctionField
        label="Datum a čas konzultace"
        render={(record) =>
          record && DateUtils.formatDateToCzechFullFormat(new Date(record.createdAt))
        }
      />
      <ReferenceField
        emptyText="-"
        label="E-mail zákonného zástupce"
        source="customer"
        reference="api/customers"
        link={false}
      >
        <TextField source="email" />
      </ReferenceField>
      {customerProfileRef != null && (
        <FunctionField
          label="Telefon zákonného zástupce"
          render={(record) =>
            `${customerProfileRef.phoneNumberPrefix} ${customerProfileRef.phoneNumber}`
          }
        />
      )}
      <ReferenceField
        emptyText="-"
        label="Voucher"
        source="voucher"
        reference="api/vouchers"
        link={false}
      >
        <FunctionField render={(record) => record && `${record.code}`} />
      </ReferenceField>
      <ReferenceField
        label=""
        source="customerChild"
        reference="api/customer-childrens"
        link={false}
      >
        <CustomLabel>Jméno dítěte</CustomLabel>
        <FunctionField render={(child) => child && `${child.firstName} ${child.surname}`} />
        <br />

        <CustomLabel style={{ marginTop: "0.5rem" }}>Datum narození dítěte</CustomLabel>
        <FunctionField
          render={(child) =>
            child && DateUtils.formatDateToCzechDMRFormat(new Date(child.birthDate))
          }
        />
      </ReferenceField>
      <br />
      <TextField label="Popis" source="healthProblemDescription" />
      <br />
      <FunctionField
        label="Doktorův komentář"
        render={(record) =>
          record && record.doctorConsultationComment
            ? `${record.doctorConsultationComment.customerAdvice}`
            : "-"
        }
      />
      <br />

      <FunctionField
        label="Interní zpráva"
        render={(record) =>
          record && record.doctorConsultationComment
            ? `${record.doctorConsultationComment.helplineReport}`
            : "-"
        }
      />
      <br />
      <ReferenceManyField
        label="Spokojenost zákonného zástupce"
        reference="api/customer-reviews"
        target="customerConsultation.id"
      >
        <Datagrid
          empty={
            <Typography component="span" variant="body2">
              -
            </Typography>
          }
          bulkActionButtons={false}
        >
          <TextField label="Komentář" source="comment" />
          <FunctionField
            label="Hodnocení"
            headerClassName={centerStyle}
            cellClassName={centerStyle}
            render={(record) => {
              return record && `${record.stars}/5`
            }}
          />
        </Datagrid>
      </ReferenceManyField>
      <br />

      <FunctionField
        label="Audio nahrávka"
        render={(record) =>
          record &&
          record.consultationAudioFile &&
          record.consultationAudioFile.length > 0 && (
            <Audio url={record.consultationAudioFile[0].contentUrl} />
          )
        }
      />
      <br />
      <p>Soubory</p>
      {Files}
    </SimpleShowLayout>
  )
}

const ShowActions = ({ permissions, role }) =>
  permissions[role].includes("u") ? (
    <TopToolbar>
      <EditButton />
    </TopToolbar>
  ) : null

const ShowEntity = (props) => {
  const { permissions } = props.entity
  const role = getRole()

  return (
    <Show
      {...props}
      actions={<ShowActions permissions={permissions} role={role} />}
      title={props.label}
    >
      <Consultation />
    </Show>
  )
}

export default ShowEntity
