import axios from "axios"

import urlUtils from "../utils/url"

export default async () => {
  const refreshToken = localStorage.getItem("refresh_token")
  const url = urlUtils.getApiUrl("api/public/token/refresh")

  // TODO: Loading
  return await axios({
    method: "get",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      refresh_token: refreshToken,
    },
  })
}
