import React from "react"
import { LinearProgress, ShowBase, SimpleShowLayout, useRecordContext } from "react-admin"

import EmployerContactFormShowEntity from "./EmployerContactFormShowEntity"
import DoctorContactFormShowEntity from "./DoctorContactFormShowEntity"

const Show = () => {
  const record = useRecordContext()

  if (!record) {
    return (
      <SimpleShowLayout>
        <LinearProgress />
      </SimpleShowLayout>
    )
  }

  if (record.type === "CONTACT_FORM_EMPLOYER") {
    return <EmployerContactFormShowEntity record={record} />
  }

  return <DoctorContactFormShowEntity record={record} />
}

const ShowEntity = () => {
  // Needs to be wrapped like this as ShowBase provides useRecordContext and also hooks are not allowed here
  return (
    <ShowBase>
      <Show />
    </ShowBase>
  )
}

export default ShowEntity
