import * as React from "react"
import { Layout } from "react-admin"

import AppBar from "./AppBar"
import CustomErrorHandler from "./CustomErrorHandler"

const CustomLayout = (props) => {
  return <Layout {...props} appBar={AppBar} error={CustomErrorHandler} />
}

export default CustomLayout
