import axios from "axios"

import getBearer from "./getBearer"

export default async (url) => {
  return await axios({
    method: "get",
    url,
    headers: {
      "Content-Type": "application/json",
      Authorization: getBearer(),
    },
  })
}
