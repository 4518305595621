import React from "react"
import { ResourceGuesser } from "@api-platform/admin"

import { getRole } from "../../utils/localStorage"
import ListEntity from "./ListEntity"
import CreateEntity from "./CreateEntity"
import EditEntity from "./EditEntity"
import ShowEntity from "./ShowEntity"

const Entity = (props) => {
  const { entity } = props

  if (!entity) {
    return null
  }

  const { name, label, icon, fields } = entity
  const role = getRole()

  if (!entity.permissions || !entity.permissions[role]) {
    // Entity is loaded, and it can be referenced from other entities
    return (
      <ResourceGuesser
        key={name}
        name={name}
        list={null}
        create={null}
        show={null}
        edit={null}
        delete={null}
      />
    )
  }

  const permissions = entity.permissions[role]
  const customProps = {}

  const show = entity.show ? entity.show(props) : ShowEntity(props)

  return (
    <ResourceGuesser
      key={name}
      name={name}
      options={{ label, fields }}
      icon={icon}
      list={permissions.includes("l") ? () => ListEntity(props) : null}
      show={permissions.includes("r") ? () => show : null}
      create={
        permissions.includes("c")
          ? () => (entity.create ? entity.create(props) : CreateEntity(props))
          : null
      }
      edit={
        permissions.includes("u")
          ? () => (entity.edit ? entity.edit(props) : EditEntity(props))
          : null
      }
      {...customProps}
    />
  )
}

export default Entity
