import getProfile from "../api/getProfile"
import { refreshToken } from "../authProvider"
import { ACCESS_DENIED, EXPIRATION, GENERAL } from "../constants/loggedOutReasons"
import { ROLE_ADMIN } from "../constants/roles"
import logout from "../utils/logout"
import * as localStorage from "../utils/localStorage"

export default async (forceRefreshToken) => {
  let profile

  if (!forceRefreshToken && localStorage.get("token")) {
    profile = await getProfile().catch(() => {})
  }

  // Profile fetch failed - refresh token
  if (!profile) {
    await refreshToken().catch(async () => {
      // Failed to refresh token
      await logout(EXPIRATION)
    })

    // The token was refreshed - let's fetch the profile
    profile = await getProfile().catch(async () => {
      // Token was refreshed but still the profile fetch failed
      await logout(GENERAL)
    })
  }

  if (!profile) {
    await logout(GENERAL)
    return
  }

  if (profile.roles[0] !== ROLE_ADMIN) {
    // For now only admins are allowed to use the Admin
    await logout(ACCESS_DENIED)
    return
  }

  localStorage.set("permissions", profile.roles)
  localStorage.set("email", profile.email)

  return profile
}
