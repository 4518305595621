import { EditButton, FunctionField, Show, SimpleShowLayout, TopToolbar } from "react-admin"
import DoctorEntity from "../doctors"

import React from "react"
import { getRole } from "../../utils/localStorage"
import * as SPECIALIZATIONS from "../../constants/specializations"

const Doctor = ({ record }) => {
  return (
    <SimpleShowLayout
      sx={{
        width: "fit-content",
        padding: "2em 10em 5em 2em",
        position: "relative",
      }}
    >
      <FunctionField label="E-mail" render={() => record.email} />
      <FunctionField label="Jméno" render={() => `${record.firstName} ${record.surname}`} />
      {record.phoneNumber && (
        <FunctionField
          label="Tel. číslo"
          render={() => `${record.phoneNumberPrefix} ${record.phoneNumber}`}
        />
      )}
      <br />
      <FunctionField
        label="Specializace"
        render={() =>
          record.specialization.map(function (specialization) {
            const { name } = specialization
            let label

            switch (name) {
              case SPECIALIZATIONS.PEDIATRY.name:
                label = SPECIALIZATIONS.PEDIATRY.label
                break
              case SPECIALIZATIONS.STOMATOLOGY.name:
                label = SPECIALIZATIONS.STOMATOLOGY.label
                break
              case SPECIALIZATIONS.GYNECOLOGY.name:
                label = SPECIALIZATIONS.GYNECOLOGY.label
                break
            }

            return <p>{label}</p>
          })
        }
      />
      <img
        src="/icons/doctor.svg"
        alt="Doctor Icon"
        style={{
          position: "absolute",
          width: "10em",
          height: "10em",
          right: "1em",
          bottom: "1em",
          zIndex: 1,
          opacity: 0.27,
        }}
      />
    </SimpleShowLayout>
  )
}

const ShowActions = ({ permissions, role }) =>
  permissions[role].includes("u") ? (
    <TopToolbar>
      <EditButton />
    </TopToolbar>
  ) : null

const ShowEntity = ({ record }) => {
  const { permissions, label } = DoctorEntity
  const role = getRole()
  return (
    <Show
      entity={DoctorEntity}
      actions={<ShowActions permissions={permissions} role={role} />}
      title={label}
      sx={{
        width: "fit-content",
        margin: "2em",
        position: "relative",
      }}
    >
      <Doctor record={record} />
    </Show>
  )
}

export default ShowEntity
