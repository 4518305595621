import React, { useEffect } from "react"
import Button from "@mui/material/Button"
import ErrorIcon from "@mui/icons-material/Report"
import History from "@mui/icons-material/History"
import { Title } from "react-admin"
import { useLocation } from "react-router-dom"

const CustomErrorHandler = ({ error, resetErrorBoundary, ...rest }) => {
  const { pathname } = useLocation()
  const originalPathname = React.useRef(pathname)

  useEffect(() => {
    if (pathname !== originalPathname.current) {
      resetErrorBoundary()
    }
  }, [pathname, resetErrorBoundary])

  useEffect(() => {
    setTimeout(() => {
      debugger
      throw error
    }, 500)
  }, [])

  return (
    <div
      style={{
        color: "rgba(0, 0, 0, 0.4)",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Title title="Error" />
      <h1
        style={{
          display: "flex",
          fontSize: "2.5rem",
          verticalAlign: "middle",
          alignItems: "center",
        }}
      >
        <ErrorIcon
          style={{ width: "2em", height: "2em", marginRight: "0.5em", verticalAlign: "middle" }}
        />
        Něco se pokazilo
      </h1>
      <div>Nastala chyba a váš požadavek nemohl být zpracován.</div>
      <div>
        <Button
          style={{ marginTop: "2em" }}
          variant="contained"
          startIcon={<History />}
          onClick={() => history.go(-1)}
        >
          Jít zpět
        </Button>
      </div>
    </div>
  )
}

export default CustomErrorHandler
