import urlUtils from "../utils/url"
import getBearer from "./getBearer"

export default (method, url, actionName, notify, refresh) => {
  const message = `Požadavek na ${actionName}`

  const fail = (errOrRes) => {
    notify(`${message} selhal`, { type: "warning" })
    console.error(errOrRes)
    refresh()
  }

  fetch(urlUtils.getApiUrl(url), {
    method,
    headers: {
      "Content-Type": "application/json",
      Authorization: getBearer(),
    },
  })
    .then((res) => {
      if (res.status >= 300) {
        fail(res)
      } else {
        notify(`${message} úspěšně zpracován`, { type: "success" })
        refresh()
      }
    })
    .catch((err) => {
      fail(err)
    })
}
