import React from "react"
import urlJoin from "proper-url-join"
import { Button, Link } from "react-admin"

import Api from "../../api/ApiRequest"

const RefundRequestIcon = () => <img src="/icons/refund.svg" alt="Refund Request Icon" />

export default {
  name: "api/refund-requests",
  label: "Refundace",
  icon: RefundRequestIcon,
  list: {
    search: {
      source: "customer.email",
      label: "Vyhledávání podle e-mailu",
    },
    filter: { state: "created" },
    actions: [
      {
        render: (record, createPath) => {
          return (
            <Link
              to={createPath({
                type: "show",
                resource: "/api/customer-consultations",
                id: record.consultation["@id"],
              })}
            >
              <Button>Zobrazit konzultaci</Button>
            </Link>
          )
        },
      },
      {
        label: "Schválit refundaci",
        confirm: {
          text: (record) => {
            return `Opravdu chcete schválit refundaci zákonného zástupce ${record.customer.email}?`
          },
        },
        handler: async (record) => {
          const url = urlJoin(record["@id"], "/refund")
          await Api.get(url)
        },
      },
      {
        label: "Zamítnout refundaci",
        confirm: {
          text: (record) => {
            return `Opravdu chcete zamítnout refundaci zákonného zástupce ${record.customer.email}?`
          },
        },
        handler: async (record) => {
          const url = urlJoin(record["@id"], "/deny")
          await Api.get(url)
        },
      },
    ],
  },
  permissions: {
    ROLE_ADMIN: "l",
  },
  fields: {
    customer: {
      source: "customer",
      label: "E-mail zákonného zástupce",
      render: (record) => {
        return `${record.customer.email}`
      },
      permissions: {
        ROLE_ADMIN: "l",
      },
    },
    description: {
      source: "description",
      label: "Popis",
      permissions: {
        ROLE_ADMIN: "l",
      },
    },
  },
}
