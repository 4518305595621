import axios from "axios"

import urlUtils from "../utils/url"
import getBearer from "./getBearer"

export default async () => {
  const url = urlUtils.getApiUrl("/api/profile")

  // TODO: Loading
  const res = await axios({
    method: "get",
    url,
    headers: {
      "Content-Type": "application/json",
      Authorization: getBearer(),
    },
  })

  return res.data
}
