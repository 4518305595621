import React from "react"

import { FieldGuesser, ShowGuesser } from "@api-platform/admin"

import { EditButton, FunctionField, ReferenceField, TextField, TopToolbar } from "react-admin"

import { getRole } from "../../utils/localStorage"
import { getFieldsByPermission } from "../../utils/permissions"

const ShowEntity = (props) => {
  const { fields, permissions } = props.entity
  const role = getRole()

  if (!fields) {
    return <ShowGuesser {...props} />
  }

  const ShowActions = ({ permissions, role }) =>
    permissions[role].includes("u") ? (
      <TopToolbar>
        <EditButton />
      </TopToolbar>
    ) : null

  const showFields = getFieldsByPermission(fields, "r")

  const content = showFields.map(function (field) {
    const { type, source, label, render } = field

    if (render) {
      return <FunctionField label={label} render={render} />
    }

    if (type === "reference") {
      const { reference, textFieldSource } = field

      return (
        <ReferenceField
          emptyText="-"
          key={field.key}
          source={source}
          label={label}
          reference={reference}
          link={false}
        >
          <TextField source={textFieldSource} />
        </ReferenceField>
      )
    }

    return <FieldGuesser emptyText="-" key={field.key} source={source} label={label} />
  })

  return (
    <ShowGuesser actions={<ShowActions permissions={permissions} role={role} />} {...props}>
      {content}
    </ShowGuesser>
  )
}

export default ShowEntity
