import { LOGOUT } from "./constants/loggedOutReasons"
import refreshTokenApiCall from "./api/refreshToken"
import getProfileApiCall from "./api/getProfile"
import _logout from "./utils/logout"
import * as localStorage from "./utils/localStorage"

const getProfile = async () => {
  return await getProfileApiCall().catch(() => {
    return
  })
}

const _refreshToken = async () => {
  const res = await refreshTokenApiCall()
  const { token, refresh_token } = res.data

  if (!token) {
    throw new Error("Refresh token failed")
  }

  localStorage.set("token", token)
  localStorage.set("refresh_token", refresh_token)
  localStorage.set("tokenIssuedAt", new Date().toISOString())
}

const refreshToken = async () => {
  return await _refreshToken()
}

const login = () => {
  // Login is handled by the Login component as it's passed to the HydraAdmin
  // It sends the login request to the API
  // stores the token and permissions to local storage
  // and redirects to root
  // The authProvider uses the local storage and everything is sunny
  return Promise.resolve()
}

const checkError = async (error) => {
  // Is called everytime when receiving a response from API
  const status = error?.response?.status
  if (status === 401 || status === 403) {
    if (window.refreshingToken) {
      // Skip all failures while token is already being refreshed
      return new Promise(() => {})
    }

    window.refreshingToken = true

    // Check that profile can be fetched after receiving an error from API
    let profile = await getProfile()

    if (profile) {
      // All good - it was some random API failure
      window.refreshingToken = false
      console.error(error)
      return Promise.resolve()
    }

    window.location.replace(
      `/?refresh_token=${localStorage.get("refresh_token")}&reload_redirect=${window.location.hash}`
    )

    return new Promise(() => {})
  }

  return Promise.resolve()
}

const checkAuth = () => {
  return localStorage.get("token") ? Promise.resolve() : Promise.reject()
}

const logout = async () => {
  await _logout(LOGOUT)
}

const getIdentity = () => {
  /* ... */
}

const getPermissions = () => {
  const role = localStorage.getRole()
  return role ? Promise.resolve(role) : Promise.reject()
}

export { refreshToken, login, checkError, checkAuth, logout, getIdentity, getPermissions }
