import React from "react"

import ShowEntity from "./ShowEntity"
import DateUtils from "../../utils/date"

const ConsultationIcon = () => <img src="/icons/consultation.svg" alt="Consultation Icon" />

export default {
  name: "api/customer-consultations",
  label: "Konzultace",
  icon: ConsultationIcon,
  list: {
    search: {
      source: "customer.email",
      label: "Vyhledávání podle e-mailu",
    },
  },
  permissions: {
    ROLE_ADMIN: "lr",
  },
  fields: {
    customer: {
      type: "reference",
      source: "customer",
      label: "E-mail zákonného zástupce",
      reference: "api/customers",
      textFieldSource: "email",
      searchTextKey: "email",
      permissions: {
        ROLE_ADMIN: "lr",
      },
    },
    code: {
      type: "reference",
      source: "voucher",
      label: "Voucher",
      reference: "api/vouchers",
      textFieldSource: "code",
      permissions: {
        ROLE_ADMIN: "lr",
      },
    },
    createdAt: {
      source: "createdAt",
      label: "Datum a čas konzultace",
      permissions: {
        ROLE_ADMIN: "lr",
      },
      render: (record) => {
        return DateUtils.formatDateToCzechFullFormat(new Date(record.createdAt))
      },
    },
  },
  show: ShowEntity,
}
