import React from "react"
import { AppBar } from "react-admin"
import Typography from "@material-ui/core/Typography"
import Toolbar from "@material-ui/core/Toolbar"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    marginLeft: -10,
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxWidth: "40px",
    marginLeft: -35,
  },
})

const CustomAppBar = (props) => {
  const classes = useStyles()

  return (
    <AppBar {...props} style={{ backgroundColor: "#1976d2", height: "3em" }}>
      <Toolbar>
        <img
          style={{ maxWidth: "125px", marginLeft: "0px" }}
          src="/logo.svg" // TODO: src="/public/logo.svg"
          alt="logo"
          className={classes.logo}
        />
      </Toolbar>
      <Typography
        style={{ padding: "0 24px" }}
        variant="h6"
        color="inherit"
        className={classes.title}
      ></Typography>
    </AppBar>
  )
}

export default CustomAppBar
