const FREE_ACCESS = "free_access"
const DISCOUNT = "discount"

export { FREE_ACCESS, DISCOUNT }

export const salesActionsType = (salesActionsType) => {
  switch (salesActionsType) {
    case FREE_ACCESS:
      return "Přístup zdarma"
    case DISCOUNT:
      return "Sleva"
    default:
      return "-"
  }
}
