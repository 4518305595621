import urlJoin from "proper-url-join"

import Api from "../../api/ApiRequest"
import React from "react"
import DateUtils from "../../utils/date"

const DeletionRequestIcon = () => <img src="/icons/deletion.svg" alt="Deletion Request Icon" />

export default {
  name: "api/customer-deletion-requests",
  label: "Žádosti o odstranění",
  icon: DeletionRequestIcon,
  permissions: {
    ROLE_ADMIN: "l",
  },
  list: {
    search: {
      source: "customer.email",
      label: "Vyhledávání podle e-mailu",
    },
    filter: { cancelled: false },
    actions: [
      {
        label: "Stornovat žádost",
        confirm: {
          text: (record) => {
            return `Opravdu chcete stornovat žádost o smazání zákonného zástupce ${record.customer.email}?`
          },
        },
        disabled: (record) => {
          return record.cancelled
        },
        handler: async (record) => {
          const url = urlJoin(record["@id"], "/cancel")
          await Api.get(url)
        },
      },
      {
        label: "Smazat zákonného zástupce",
        confirm: {
          text: (record) => {
            return `Opravdu chcete smazat zákonného zástupce ${record.customer.email}?`
          },
        },
        disabled: (record) => {
          return (
            record.cancelled ||
            new Date(record.possibleDeleteDate).setHours(0, 0, 0, 0) >
              new Date().setHours(0, 0, 0, 0)
          )
        },
        handler: async (record) => {
          const url = urlJoin(record["@id"], "/delete-customer")
          await Api.get(url)
        },
      },
    ],
  },
  fields: {
    email: {
      render: (record) => {
        return `${record.customer.email}`
      },
      label: "E-mail",
      permissions: {
        ROLE_ADMIN: "l",
      },
    },
    createdAt: {
      source: "createdAt",
      label: "Datum vytvoření",
      permissions: {
        ROLE_ADMIN: "l",
      },
      render: (record) => {
        return DateUtils.formatDateToCzechFullFormat(new Date(record.createdAt))
      },
    },
    reason: {
      source: "reason",
      label: "Důvod",
      permissions: {
        ROLE_ADMIN: "l",
      },
    },
    possibleDeleteDate: {
      source: "possibleDeleteDate",
      label: "Datum možného smazání",
      //  sortable: true, // TODO: Sortable dynamic date?
      render: (record) => {
        return DateUtils.formatDateToCzechFullFormat(new Date(record.possibleDeleteDate))
      },
      permissions: {
        ROLE_ADMIN: "l",
      },
    },
  },
}
