import * as React from "react"

import { Loading as ReactAdminLoading } from "react-admin"

function Loading({ loadingPrimary, loadingSecondary }) {
  return (
    <ReactAdminLoading
      loadingPrimary={loadingPrimary || ""}
      loadingSecondary={loadingSecondary || ""}
    />
  )
}

export default Loading
