import React, { useEffect, useState } from "react"

import { FieldGuesser, ListGuesser } from "@api-platform/admin"

import {
  BulkDeleteButton,
  Button,
  Confirm,
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField,
  List as ReactAdminList,
  Pagination as RaPagination,
  PaginationActions as RaPaginationActions,
  ReferenceField,
  ShowButton,
  TextField,
  TextInput,
  TopToolbar,
  useCreatePath,
  useListContext,
  useNotify,
  useRefresh,
} from "react-admin"

import { Box, Typography } from "@mui/material"

import { getRole } from "../../utils/localStorage"
import { Styles } from "../../styles/Styles"
import { getFieldsByPermission } from "../../utils/permissions"

const ListEntity = (props) => {
  const { centerStyle } = Styles()
  const role = getRole()
  const { fields, list, permissions } = props.entity
  const search = list && list.search
  const filter = list && list.filter
  const multipleDelete = list && list.multipleDelete
  const [pagingInitialized, setPagingInitialized] = useState(false)

  const [confirmOpen, setConfirmOpen] = useState(false)
  const [confirmContent, setConfirmContent] = useState("")
  const [confirmHandler, setConfirmHandler] = useState()

  const notify = useNotify()
  const refresh = useRefresh()
  const createPath = useCreatePath()

  const handleConfirmClose = () => {
    setConfirmOpen(false)
    setConfirmHandler(undefined)
  }

  const renderCustomRowActions = (actions, record) => {
    return actions.map(({ render, label, handler, disabled, confirm }) => {
      if (render) {
        return render(record, createPath)
      }

      return (
        <Button
          disabled={disabled ? disabled(record) : false}
          onClick={async () => {
            try {
              if (confirm) {
                setConfirmContent(confirm.text(record))
                setConfirmHandler(() => async () => {
                  handleConfirmClose()
                  try {
                    await handler(record)
                    notify(`Požadavek proběhl úspěšně`)
                  } catch (err) {
                    notify(`Požadavek selhal`, { type: "warning" })
                  }
                  refresh()
                })
                setConfirmOpen(true)
              } else {
                await handler(record)
                notify(`Požadavek proběhl úspěšně`)
                refresh()
              }
            } catch (err) {
              notify(`Požadavek selhal`, { type: "warning" })
              refresh()
            }
          }}
        >
          {label}
        </Button>
      )
    })
  }

  if (!fields) {
    return <ListGuesser {...props} sortable={false} />
  }

  const listFields = getFieldsByPermission(fields, "l")

  const content = listFields.map(function (field) {
    const { type, source, label, render, sortable } = field

    if (sortable === undefined) {
      field.sortable = false
    }

    if (type === "reference") {
      const { reference, textFieldSource } = field

      return (
        <ReferenceField
          emptyText="-"
          key={field.key}
          source={source}
          label={label}
          reference={reference}
          sortable={sortable}
          link={false}
        >
          {render ? (
            <FunctionField label={label} sortable={sortable} render={(record) => render(record)} />
          ) : (
            <TextField source={textFieldSource} />
          )}
        </ReferenceField>
      )
    }

    if (render) {
      return (
        <FunctionField
          key={field.key}
          source={source}
          label={label}
          sortable={sortable}
          render={(record) => `${render(record)}`}
        />
      )
    }

    return (
      <FieldGuesser
        emptyText="-"
        key={field.key}
        source={source}
        label={label}
        sortable={sortable}
        link={false}
      />
    )
  })

  const postFilters = search
    ? [
        <TextInput
          sx={{
            width: "20rem",
          }}
          label={search.label}
          source={search.source}
          alwaysOn
        />,
      ]
    : null

  const ListActions = () =>
    permissions[role].includes("c") ? (
      <TopToolbar>
        <CreateButton />
      </TopToolbar>
    ) : null

  const displayShowButton = permissions[role].includes("r")
  const displayEditButton = permissions[role].includes("u")

  const PaginationActions = (props) => (
    <RaPaginationActions {...props} color="primary" showFirstButton showLastButton />
  )

  const Pagination = (props) => {
    const MIN_PER_PAGE = 25
    const DEFAULT_PER_PAGE = 50
    const MAX_PER_PAGE = 100
    const ROWS_PER_PAGE_OPTIONS = [MIN_PER_PAGE, DEFAULT_PER_PAGE, MAX_PER_PAGE]

    const { total, perPage, setPerPage } = useListContext()

    useEffect(() => {
      if (pagingInitialized) {
        localStorage.setItem("perPage", perPage)
        return
      }

      if (localStorage.getItem("perPage") === null) {
        localStorage.setItem("perPage", DEFAULT_PER_PAGE)
        setPerPage(DEFAULT_PER_PAGE)
      } else {
        const storedPerPage = parseInt(localStorage.getItem("perPage"))
        setPerPage(storedPerPage)
      }

      setPagingInitialized(true)
    }, [perPage])

    return (
      <>
        <p
          style={{
            marginTop: "2rem",
            marginBottom: "0.5rem",
            fontWeight: 400,
            fontSize: "0.875rem",
            letterSpacing: "0.01071em",
          }}
        >
          Celkem záznamů: <span style={{ marginLeft: "0.25rem" }}>{total}</span>
        </p>
        {total > MIN_PER_PAGE ? (
          <RaPagination
            perPage={perPage}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            sx={{
              ".MuiToolbar-root": { paddingLeft: 0, marginTop: "0px" },
              ".MuiInputBase-root": { position: "absolute", left: "9rem", top: "1.1rem" },
              ".MuiTablePagination-selectLabel": { position: "absolute" },
              ".MuiTablePagination-actions": { marginTop: "-0.5rem" },
              ".MuiTablePagination-displayedRows": {
                display: "none",
              },
            }}
            {...props}
            ActionsComponent={PaginationActions}
          />
        ) : null}
      </>
    )
  }

  const Empty = () => (
    <Box style={{ flex: 1, padding: "2rem" }} textAlign="center" m={1}>
      <Typography
        variant="h5"
        paragraph
        style={{
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          color: "rgba(0, 0, 0, 0.5)",
        }}
      >
        Žádné položky k zobrazení
      </Typography>
    </Box>
  )

  const adminListProps = { ...props }

  adminListProps.pagination = <Pagination />
  adminListProps.actions = <ListActions />
  adminListProps.filters = postFilters
  adminListProps.filter = filter
  adminListProps.empty = <Empty />

  if (props.entity.customEntity && props.entity.customEntity === "ADMIN") {
    adminListProps.resource = "api/users"
    adminListProps.filter = { ...adminListProps.filter, "roles[]": "ROLE_ADMIN" }
  }

  if (props.entity.customEntity && props.entity.customEntity === "DOCTOR") {
    adminListProps.resource = "api/users"
    adminListProps.filter = { ...adminListProps.filter, "roles[]": "ROLE_DOCTOR" }
  }

  return (
    <>
      <ReactAdminList {...adminListProps}>
        <Datagrid bulkActionButtons={multipleDelete ? <BulkDeleteButton /> : false}>
          {content}
          <FunctionField
            label="Akce"
            headerClassName={centerStyle}
            cellClassName={centerStyle}
            render={(record) => (
              <>
                {displayShowButton ? <ShowButton label="" /> : null}
                {displayEditButton ? <EditButton label="" /> : null}
                {list?.actions ? renderCustomRowActions(list.actions, record) : null}
              </>
            )}
          />
        </Datagrid>
      </ReactAdminList>
      <Confirm
        isOpen={confirmOpen}
        content={confirmContent}
        onConfirm={() => confirmHandler()}
        onClose={() => handleConfirmClose()}
      />
    </>
  )
}

export default ListEntity
