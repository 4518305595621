import {
  Button,
  Confirm,
  Datagrid,
  FunctionField,
  LinearProgress,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useCreatePath,
  useGetManyReference,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin"

import { Link } from "react-router-dom"
import React, { useState } from "react"
import VisibilityIcon from "@mui/icons-material/Visibility"

import apiCall from "../../api/apiCall"
import { CustomLabel } from "../../components/Components/CustomLabel"
import { Styles } from "../../styles/Styles"
import { refundState, voucherState } from "../../constants/strings"
import DateUtils from "../../utils/date"

const Refunds = (customer) => {
  const createPath = useCreatePath()

  const { data, isLoading } = useGetManyReference("api/refund-requests", {
    target: "customer.email",
    id: customer.email,
  })

  const consultationDetailLink = (consultation) => (
    <Link
      to={createPath({
        type: "show",
        resource: "/api/customer-consultations",
        id: consultation["@id"],
      })}
    >
      <VisibilityIcon style={{ fontSize: "18px", color: "#1976d2" }} />
    </Link>
  )

  const refunds = (
    <Datagrid
      empty="-"
      isLoading={isLoading}
      data={data}
      sort={{ field: "id", order: "DESC" }}
      bulkActionButtons={false}
    >
      <ReferenceField
        emptyText="-"
        label="Kód voucheru"
        source="consultation.voucher"
        reference="vouchers"
        link={false}
      >
        <TextField label="Voucher" source="code" />
      </ReferenceField>
      <FunctionField label="Stav" render={(refund) => refundState(refund.state)} />
      <FunctionField
        label="Akce"
        render={(refund) => consultationDetailLink(refund.consultation)}
      />
    </Datagrid>
  )

  return (
    <SimpleShowLayout style={{ marginLeft: "-1rem" }}>
      <CustomLabel>Požadavky na refundace konzultací</CustomLabel>
      {refunds}
    </SimpleShowLayout>
  )
}

const Customer = () => {
  const customer = useRecordContext()
  const createPath = useCreatePath()
  const { centerStyle } = Styles()

  if (!customer) {
    return (
      <SimpleShowLayout>
        <LinearProgress />
      </SimpleShowLayout>
    )
  }

  const notify = useNotify()
  const refresh = useRefresh()
  const refunds = Refunds(customer)
  const [open, setOpen] = useState(false)
  const [voucher, setVoucher] = useState(false)

  const openVoucherDeactivateConfirm = (voucher) => {
    setVoucher(voucher)
    setOpen(true)
  }

  const deactivateVoucher = () => {
    handleDialogClose()
    // TODO: Loader
    apiCall("GET", `${voucher.id}/deactivate`, "zneplatnění voucheru", notify, refresh)
    setVoucher(null)
  }

  const handleDialogClose = () => setOpen(false)

  return (
    <SimpleShowLayout>
      <ReferenceField
        source="customerProfile"
        label="Jméno"
        reference="api/customer-profiles"
        link={false}
        emptyText="-"
      >
        <FunctionField
          render={(profile) => {
            return (
              profile && (
                <>
                  {profile.firstName} {profile.surname}
                </>
              )
            )
          }}
        />
      </ReferenceField>
      <br />
      <FunctionField
        label="E-mail"
        render={() => {
          return customer.email
        }}
      />
      <br />
      <ReferenceField
        source="customerProfile"
        label="Telefon"
        reference="api/customer-profiles"
        link={false}
        emptyText="-"
      >
        <FunctionField
          render={(profile) => {
            return (
              profile && (
                <>
                  {profile.phoneNumberPrefix} {profile.phoneNumber}
                </>
              )
            )
          }}
        />
      </ReferenceField>
      <br />
      <ReferenceArrayField
        label="Děti"
        source="customerChildren"
        reference="api/customer-childrens"
      >
        <Datagrid bulkActionButtons={false} empty="-">
          <FunctionField
            label="Jméno"
            render={(child) => {
              return child && `${child.firstName} ${child.surname}`
            }}
          />
          <FunctionField
            label="Datum narození"
            render={(child) => {
              return child && DateUtils.formatDateToCzechDMRFormat(new Date(child.birthDate))
            }}
          />
        </Datagrid>
      </ReferenceArrayField>
      <br />
      {refunds}
      <br />
      <ReferenceArrayField label="Vouchery" source="vouchers" reference="api/vouchers">
        <Datagrid empty="-" bulkActionButtons={false}>
          <TextField label="Kód" source="code" />
          <FunctionField
            label="Stav"
            render={(voucher) => {
              return voucherState(voucher.status)
            }}
          />
          <FunctionField
            label="Datum vytvoření"
            render={(voucher) => {
              return DateUtils.formatDateToCzechDMRFormat(new Date(voucher.createdAt))
            }}
          />
          <ReferenceField
            emptyText="-"
            source="creator"
            label="Tvůrce"
            reference="api/users"
            link={false}
          >
            <FunctionField
              render={(user) => {
                return user && user.email
              }}
            />
          </ReferenceField>

          <FunctionField
            label="Akce"
            headerClassName={centerStyle}
            cellClassName={centerStyle}
            render={(voucher) => {
              return (
                voucher && (
                  <>
                    <Link
                      to={createPath({
                        type: "show",
                        resource: "/api/vouchers",
                        id: voucher["@id"],
                      })}
                    >
                      <Button>Zobrazit</Button>
                    </Link>
                    <Button
                      disabled={voucher.status !== "CREATED"}
                      onClick={() => openVoucherDeactivateConfirm(voucher)}
                    >
                      Zneplatnit
                    </Button>
                  </>
                )
              )
            }}
          />
        </Datagrid>
      </ReferenceArrayField>
      <Confirm
        isOpen={open}
        title="Zneplatnění voucheru"
        content={`Opravdu chcete zneplatnit voucher ${voucher?.code}?`}
        onConfirm={deactivateVoucher}
        onClose={handleDialogClose}
      />
    </SimpleShowLayout>
  )
}

const ShowEntity = (props) => {
  return (
    <Show {...props} title={props.label}>
      <Customer />
    </Show>
  )
}

export default ShowEntity
